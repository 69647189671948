import React from "react";
import { PulseLoader } from "react-spinners";

type Props = {
  size?: "small" | "medium" | "large";
  color?: string;
  message?: string;
};

export default function LoadingSpinner({ color }: Props) {
  return (
    <div>
      <PulseLoader color={color || "orange"} />
    </div>
  );
}

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { store } from "../app/store";

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "development"
      ? "https://ae4e-41-155-6-223.ngrok-free.app/graphql"
      : "https://shy.live.groceryhub.store/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = store.getState().user.token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;

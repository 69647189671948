import { RouteObject } from "react-router-dom";
import StoreFront from "./layouts/StoreFront";
import HomePage from "./pages/HomePage";
import Checkout from "./pages/Checkout";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import CategoryPage from "./pages/CategoryPage";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <StoreFront />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/category/:slug",
        element: <CategoryPage />,
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "/checkout-success",
        element: <CheckoutSuccess />,
      },
    ],
    // errorElement: <ErrorPage />,
  },
];

import { useCallback, useEffect, useState } from "react";
import { useProducts } from "../../hooks/useProducts";
import { useParams } from "react-router-dom";
import { ProductItem } from "../../components";
import LoadingSpinner from "../../components/LoadingSpinner";
import { superstate } from "@superstate/core";
import { useInView } from "react-hook-inview";

type Props = {};

const LIMIT = 20;
const offset = superstate(0);

const CategoryPage = (props: Props) => {
  const [ref, isVisible] = useInView({
    threshold: 1,
  });
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const { products, loading, length, fetchMore } = useProducts({
    filter: {
      category_id: {
        eq: slug || "",
      },
    },
  });

  const handleOnEndReached = useCallback(async () => {
    if (length > offset.now() + LIMIT && !isLoading) {
      setIsLoading(true);
      try {
        await fetchMore({
          variables: {
            pagination: {
              offset: offset.now() + LIMIT,
              limit: LIMIT,
            },
            filter: {
              category_id: {
                eq: slug || "",
              },
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            offset.set(offset.now() + LIMIT);
            if (!fetchMoreResult) return prev;
            return {
              getProducts:
                prev.getProducts?.concat(fetchMoreResult.getProducts || []) ||
                [],
              getProductsLength: fetchMoreResult.getProductsLength || 0,
            };
          },
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      handleOnEndReached();
    }
  }, [isVisible, handleOnEndReached]);

  return (
    <div className="min-h-screen bg-gray-50">
      <section
        aria-labelledby="trending-heading"
        className="bg-gray-50 py-8 px-4 sm:px-6 lg:px-8"
      >
        <div className="mx-auto max-w-7xl">
          <div className="md:flex md:items-center md:justify-between mb-6">
            <h2
              id="trending-heading"
              className="text-2xl font-bold tracking-tight text-gray-900"
            >
              Products
            </h2>
          </div>
          <div className="grid grid-cols-2 gap-y-8 gap-x-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {products?.map((product) => (
              <ProductItem product={product} key={product.id} />
            ))}
          </div>

          {/* Infinite Scroll Trigger / Footer */}
          <div ref={ref} className="mt-8 text-center">
            {loading ? (
              <div className="flex justify-center py-4">
                <LoadingSpinner size="small" />
              </div>
            ) : length > offset.now() + LIMIT ? (
              <p className="text-sm text-gray-500">Scroll for more products</p>
            ) : (
              <p className="text-sm text-gray-500">You've reached the end</p>
            )}
          </div>
        </div>
      </section>
      {/* Infinite Scroll Trigger / Footer */}
      <div ref={ref} className="mt-8 text-center">
        {isLoading ? (
          <div className="flex justify-center py-4">
            <LoadingSpinner size="small" />
          </div>
        ) : length > offset.now() + LIMIT ? (
          <p className="text-sm text-gray-500">Scroll for more products</p>
        ) : (
          <p className="text-sm text-gray-500">You've reached the end</p>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ProductStatus {
  available = "available",
  out_of_stock = "out_of_stock",
  unlisted = "unlisted",
}

export enum ProductType {
  price = "price",
  quantity = "quantity",
  weight = "weight",
}

export interface AuthInput {
  otp?: string | null;
  token?: string | null;
}

export interface BooleanOperator {
  eq?: boolean | null;
  in?: boolean[] | null;
  notIn?: boolean[] | null;
}

export interface CategoryFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
  image?: StringOperator | null;
  description?: StringOperator | null;
  color?: StringOperator | null;
  createdAt?: DateOperator | null;
  updatedAt?: DateOperator | null;
}

export interface CreateOrderInput {
  total: number;
  items?: (OrderProduct | null)[] | null;
  delivery: DeliveryInput;
  customerPhone: string;
  customerName: string;
  paymentRef: string;
}

export interface DateOperator {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  between?: any[] | null;
}

export interface DeliveryInput {
  zone: string;
  estate: string;
  suite: string;
}

export interface FloatOperator {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  between?: number[] | null;
}

export interface IdOperator {
  eq?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
}

export interface IntOperator {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  between?: number[] | null;
}

export interface OrderProduct {
  id: string;
  amount: number;
  quantity: number;
}

export interface Pagination {
  offset?: number | null;
  limit?: number | null;
}

export interface ProductFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
  category_id?: IdOperator | null;
  type?: ProductTypeOperator | null;
  status?: ProductStatusOperator | null;
  isNew?: BooleanOperator | null;
  price?: FloatOperator | null;
  stock?: IntOperator | null;
  createdAt?: DateOperator | null;
  updatedAt?: DateOperator | null;
}

export interface ProductStatusOperator {
  eq?: ProductStatus | null;
  in?: ProductStatus[] | null;
  notIn?: ProductStatus[] | null;
}

export interface ProductTypeOperator {
  eq?: ProductType | null;
  in?: ProductType[] | null;
  notIn?: ProductType[] | null;
}

export interface StringOperator {
  eq?: string | null;
  contains?: (string | null)[] | null;
  in?: string[] | null;
  notIn?: string[] | null;
  regex?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
}

export interface UserInput {
  name: string;
  phone: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

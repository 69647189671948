import { Modal } from "@mantine/core";
import React, { useMemo, useState } from "react";
import { GetProducts_getProducts } from "../../graphql/queries/__generated__/GetProducts";
import { useAppDispatch } from "../../app/hooks";
import { addItemToCart } from "../../features/cart";
import { ProductType } from "../../graphql/__generated__/globalTypes";
import { formatCurrency } from "../../utils/formatters";

type Props = {
  product: GetProducts_getProducts;
};

const ProductItem = ({ product }: Props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [qty, setQty] = useState(1);
  const [amount, setAmount] = useState<number>(product?.price);
  const dispatch = useAppDispatch();

  const isQuantity = useMemo(
    () => product?.type === ProductType["quantity"],
    [product?.type]
  );

  const handleAddToCart = () => {
    setShowDetails(false);
    dispatch(
      addItemToCart({
        ...product,
        qty,
        amount,
      })
    );
  };

  const totalPrice = isQuantity ? product.price * qty : amount;

  return (
    <>
      <div className="group relative">
        <div
          onClick={() => setShowDetails(true)}
          className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg cursor-pointer bg-gray-100"
        >
          <img
            src={product?.image}
            alt={product?.name}
            className="h-36 w-full object-cover object-center transition-opacity group-hover:opacity-75"
          />
          {product?.price && (
            <span className="absolute top-2 right-2 bg-primary-600 text-white px-2 py-1 text-xs font-medium rounded-full">
              New
            </span>
          )}
        </div>
        <div className="mt-4 space-y-1">
          <h3 className="text-sm font-medium text-gray-900">{product?.name}</h3>
          <p className="text-sm text-gray-500">{product?.name}</p>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900">
              {formatCurrency(product?.price)}
            </p>
            <button
              onClick={() => setShowDetails(true)}
              className="text-sm font-medium text-primary-600 hover:text-primary-500"
            >
              Add to cart
            </button>
          </div>
        </div>
      </div>

      <Modal
        opened={showDetails}
        onClose={() => setShowDetails(false)}
        title={
          <h2 className="text-xl font-semibold text-gray-900">
            {product?.name}
          </h2>
        }
        size="lg"
        padding="lg"
      >
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-100">
            <img
              src={product?.image}
              alt={product?.name}
              className="h-48 w-full object-cover object-center"
            />
          </div>

          <div className="flex flex-col">
            <div className="flex-1 space-y-4">
              <p className="text-gray-500">{product?.type}</p>
              <div className="flex items-center justify-between">
                <span className="text-lg font-medium text-gray-900">
                  Price:
                </span>
                <span className="text-lg font-medium text-gray-900">
                  {formatCurrency(product?.price)}
                </span>
              </div>

              {isQuantity ? (
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Quantity
                  </label>
                  <div className="flex items-center justify-center border rounded-md w-32">
                    <button
                      onClick={() => setQty((prev) => Math.max(1, prev - 1))}
                      className="p-2 hover:bg-gray-100"
                      aria-label="Decrease quantity"
                    >
                      <MinusIcon className="w-4 h-4" />
                    </button>
                    <span className="flex-1 text-center py-2">{qty}</span>
                    <button
                      onClick={() => setQty((prev) => prev + 1)}
                      className="p-2 hover:bg-gray-100"
                      aria-label="Increase quantity"
                    >
                      <PlusIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="space-y-2">
                  <label
                    htmlFor="amount"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Amount
                  </label>
                  <input
                    id="amount"
                    type="number"
                    min={product?.price}
                    value={amount}
                    onChange={(e) => setAmount(e.target.valueAsNumber)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                    placeholder={`Minimum ${formatCurrency(product?.price)}`}
                  />
                </div>
              )}

              <div className="border-t pt-4">
                <div className="flex items-center justify-between text-lg font-medium">
                  <span>Total:</span>
                  <span>{formatCurrency(totalPrice)}</span>
                </div>
              </div>
            </div>

            <button
              onClick={handleAddToCart}
              className="mt-6 w-full rounded-md border border-transparent bg-primary-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              Add to Cart
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

// Helper components for icons
const MinusIcon = ({ className = "w-6 h-6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 12H4"
    />
  </svg>
);

const PlusIcon = ({ className = "w-6 h-6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 4v16m8-8H4"
    />
  </svg>
);

export default ProductItem;

import { gql } from "@apollo/client";

export const getProducts = gql`
query GetProducts($pagination: Pagination, $filter: ProductFilter) {
  getProducts(pagination: $pagination, filter: $filter) {
    id
    name
    image
    type
    price
    status
  }
  getProductsLength(filter: $filter)
}
`;

export const getMe = gql`
  query Me {
    me {
      token
      user {
        id
        name
        phone
      }
    }
  }
`;

export const getCategories = gql`
query GetCategories($filter: CategoryFilter, $pagination: Pagination) {
  getCategories(filter: $filter, pagination: $pagination) {
    id
    name
    image
    description
    createdAt
    updatedAt
  }
}

`;

import { useQuery } from "@apollo/client";
import { GetCategories, GetCategoriesVariables } from "../graphql/queries/__generated__/GetCategories";
import { getCategories } from "../graphql/queries/products";

export const useCategories = (variables?: GetCategoriesVariables) => {
    const { data, ...rest } = useQuery<GetCategories, GetCategoriesVariables>(getCategories, {
        variables,
    })
    return {
        categories: data?.getCategories || [],
        ...rest
    }
};